"use client";

import { useState } from "react";
import { publishEvent } from "src/events/functions";
import {
  confirmationEvents,
  EventName,
  ConfirmationOpenEvent,
  eventNames,
} from "src/events/model";
import { useListenForEvents } from "src/events/hooks";
import { Modal, ModalButtonsContainer } from "src/components/Modal";
import { SecondaryButton } from "src/components/SecondaryButton";
import { PrimaryButton } from "src/components/PrimaryButton";

export function ConfirmationModal() {
  const [show, setShow] = useState(false);
  const [opener, setOpener] = useState<ConfirmationOpenEvent | "">("");

  const disabled = opener === "";

  const messages: Record<ConfirmationOpenEvent | "", string> = {
    openWithdraw: "You are withdrawing funds from your wallet.",
    openAddDebt: "Your loan amount for this loan will increase.",
    openRepayLoan: "Your loan will be paid in its entirety.",
    openRepayLoanPartially: "A part of your loan will be repaid.",
    openCreateLoan: "This action will open a new loan.",
    openWithdrawCollateral: "This will withdraw collateral from this loan.",
    openDepositCollateral:
      "This will deposit additional collateral to this loan.",
    "": "This action needs confirmation.",
  };

  function onEventCapture(eventName: EventName) {
    setOpener(eventName as ConfirmationOpenEvent);
    setShow(true);
  }

  function onUnsubscribe() {
    setOpener("");
    setShow(false);
  }

  useListenForEvents({
    events: [...eventNames.confirmation.openers],
    subscribeListener: onEventCapture,
    unsubscribeListener: onUnsubscribe,
  });

  function onClose() {
    setOpener("");
    setShow(false);
  }

  function onConfirm() {
    if (opener) {
      Object.values(confirmationEvents).forEach((event) => {
        if (event.opener === opener) {
          publishEvent(event.confirmer);
        }
      });
    }
    onClose();
  }

  return (
    <Modal show={show} onClose={() => setShow(false)}>
      <div className="items-left z-50 flex flex-col gap-10">
        <p>{messages[opener]} Proceed?</p>
        <ModalButtonsContainer>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton disabled={disabled} onClick={onConfirm}>
            Confirm
          </PrimaryButton>
        </ModalButtonsContainer>
      </div>
    </Modal>
  );
}
