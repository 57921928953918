"use client";

import { FormEvent, useEffect, useState } from "react";
import { ConfirmationEventsKey, confirmationEvents, EventName } from "./model";
import {
  publishEvent,
  subscribeEvent,
  subscribeEvents,
  unsubscribeEvent,
  unsubscribeEvents,
} from "./functions";

type UseEventArgs = {
  eventName: EventName;
  subscribeListener: (eventName: EventName) => void;
  unsubscribeListener: () => void;
};

export function useListenForEvent(args: UseEventArgs) {
  const { eventName, subscribeListener, unsubscribeListener } = args;

  useEffect(() => {
    subscribeEvent(eventName, subscribeListener);

    return () => {
      unsubscribeEvent(eventName, unsubscribeListener);
    };
  }, []);
}

type UseEventsArgs = {
  events: EventName[];
  subscribeListener: (eventName: EventName) => void;
  unsubscribeListener: () => void;
};

export function useListenForEvents(args: UseEventsArgs) {
  const { events, subscribeListener, unsubscribeListener } = args;

  useEffect(() => {
    subscribeEvents(events, subscribeListener);

    return () => {
      unsubscribeEvents(events, unsubscribeListener);
    };
  }, []);
}

/**
 * Allows a component to delay the execution of a function until the user has confirmed it.
 * @param eventName The event to listen to & dispatch.
 * @param onConfirmed The function to execute on confirmation.
 * @returns A function body which publishes and dispatches an ConfirmationEventOpener event. Triggers opening the ConfirmationModal.
 */
export function useConfirmationModal(
  eventName: ConfirmationEventsKey,
  onConfirmed: () => void
) {
  const [confirmed, setConfirmed] = useState(false);
  const [isDispatcher, setIsDispatcher] = useState(false);

  // listens for confirmations
  useListenForEvent({
    eventName: confirmationEvents[eventName].confirmer,
    subscribeListener: () => setConfirmed(true),
    unsubscribeListener: () => setConfirmed(false),
  });

  useEffect(() => {
    // only execute if the component has dispatched the event
    if (confirmed && isDispatcher) {
      onConfirmed();
    }

    setConfirmed(false);
    setIsDispatcher(false);
  }, [confirmed]);

  function openConfirmationModal(e?: FormEvent) {
    e?.preventDefault();

    publishEvent(confirmationEvents[eventName].opener);
    setIsDispatcher(true);
  }

  return {
    openConfirmationModal,
  };
}
