import { EventName } from "./model";

/**
 * Adds an event listener for a custom event.
 * @param eventName Name of the custom event. Must be of EventName type.
 * @param listener Function to be executed on event firing. Takes in the current event name as a parameter.
 */
export function subscribeEvent(
  eventName: EventName,
  listener: (eventName: EventName) => void
) {
  document.addEventListener(eventName, () => listener(eventName));
}

/**
 * Adds event listeners for a list of custom events.
 * @param eventNames Names of the custom events. Must be of EventName type.
 * @param listener Function to be executed on event firing. Takes in the current event name as a parameter.
 */
export function subscribeEvents(
  eventNames: EventName[],
  listener: (eventName: EventName) => void
) {
  for (const eventName of eventNames) {
    subscribeEvent(eventName, () => listener(eventName));
  }
}

/**
 * Removes an existing event listener.
 * @param eventName Name of the custom event. Must be of EventName type.
 * @param listener Function to be executed on event firing. 
 */
export function unsubscribeEvent(eventName: EventName, listener: () => void) {
  document.removeEventListener(eventName, listener);
}

/**
 * Removes event listeners for a list of custom events.
 * @param eventNames Names of the custom events. Must be of EventName type.
 * @param listener Function to be executed on event firing.
 */
export function unsubscribeEvents(eventNames: EventName[], listener: () => void) {
  for (const eventName of eventNames) {
    unsubscribeEvent(eventName, listener);
  }
}

/**
 * Makes a custom event available for subscription for all components and fires off event.
 * @param eventName Name of the custom event. Must be of EventName type.
 */
export function publishEvent(eventName: EventName) {
  const event = new CustomEvent(eventName);
  document.dispatchEvent(event);
}
