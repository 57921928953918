export const eventNames = {
  confirmation: {
    openers: [
      "openWithdraw",
      "openAddDebt",
      "openWithdrawCollateral",
      "openDepositCollateral",
      "openRepayLoan",
      "openRepayLoanPartially",
      "openCreateLoan",
    ],
    confirmers: [
      "confirmWithdraw",
      "confirmAddDebt",
      "confirmWithdrawCollateral",
      "confirmDepositCollateral",
      "confirmRepayLoan",
      "confirmRepayLoanPartially",
      "confirmCreateLoan",
    ],
  },
} as const;

export type ConfirmationOpenEvent =
  (typeof eventNames.confirmation.openers)[number];

type ConfirmationConfirmEvent =
  (typeof eventNames.confirmation.confirmers)[number];

export type EventName = ConfirmationOpenEvent | ConfirmationConfirmEvent;

// #region Confirmation events

export type ConfirmationEventsKey =
  | "addDebt"
  | "withdraw"
  | "withdrawCollateral"
  | "depositCollateral"
  | "repayLoan"
  | "repayLoanPartially"
  | "createLoan";

type ConfirmationEventsLeaf = {
  opener: ConfirmationOpenEvent;
  confirmer: ConfirmationConfirmEvent;
};

export const confirmationEvents: Record<
  ConfirmationEventsKey,
  ConfirmationEventsLeaf
> = {
  addDebt: {
    opener: "openAddDebt",
    confirmer: "confirmAddDebt",
  },
  withdraw: {
    opener: "openWithdraw",
    confirmer: "confirmWithdraw",
  },
  withdrawCollateral: {
    opener: "openWithdrawCollateral",
    confirmer: "confirmWithdrawCollateral",
  },
  depositCollateral: {
    opener: "openDepositCollateral",
    confirmer: "confirmDepositCollateral",
  },
  repayLoan: {
    opener: "openRepayLoan",
    confirmer: "confirmRepayLoan",
  },
  repayLoanPartially: {
    opener: "openRepayLoanPartially",
    confirmer: "confirmRepayLoanPartially",
  },
  createLoan: {
    opener: "openCreateLoan",
    confirmer: "confirmCreateLoan",
  },
} as const;

// #endregion
